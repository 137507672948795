import React from 'react';
import { useSelector } from 'react-redux';

import AuthStateProvider from '../components/authStateProvider';
import Header from '../components/header/header';
import Footer from '../components/footer';
import MembershipComponent from '../components/membership/index';

import SEO from '../components/SEO';

const Membership = (props) => {
  const auth = useSelector((state) => state.auth);

  return (
    <AuthStateProvider authRequired {...props}>
      <SEO title="Membership" />
      <Header show />
      <MembershipComponent initValue={auth} />
      <Footer variant="post-login-footer" />
    </AuthStateProvider>
  );
};

export default Membership;
