import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Image, Button, Spinner, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
//import { getMembershipDetails } from '../../actions/membership';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import logo from '../../assets/logo.png';
import { getStripeClinetSecret, payWithCard, addUserToMembership } from '../../actions/register';
import { TYPE_LOCK_INTERRUPT_MINI_PLAYER, TYPE_UNLOCK_INTERRUPT_MINI_PLAYER } from '../../store/reducer';
import './style.scss';

// Need to use Live Key for production
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISH_KEY);

const useOptions = () => {
  const options = useMemo(() => ({
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }));

  return options;
};

const MembershipWrap = (props) => (
  <Elements stripe={stripePromise}>
    <Membership {...props} />
  </Elements>
);

const Membership = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  // const [details, setDetails] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [intent, setIntent] = useState('');
  const { auth } = useSelector((state) => state);
  const { memberships = [], payments } = auth;
  const dispatch = useDispatch();

  const memberType = memberships.length && memberships[0].membership;
  useEffect(() => {
    async function getClinetSecret() {
      const response = await getStripeClinetSecret(39.99);
      setIntent(response.client_secret);
    }
    if (memberType === 'Free Trial') {
      getClinetSecret();
    }
    dispatch({ type: TYPE_LOCK_INTERRUPT_MINI_PLAYER });

    return () => {
      dispatch({ type: TYPE_UNLOCK_INTERRUPT_MINI_PLAYER });
    };
  }, []);

  const ViewReciptModal = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-receipt"
        size="lg"
        style={{ backgroundColor: '#DCDCDC' }}
      >
        <Modal.Header closeButton>
          <Image src={logo} />
          <div className="alignright">
            <h1>Invoice {data.invoice_id}</h1>
          </div>
        </Modal.Header>
        <Modal.Body>
          <section id="receipt">
            <div className="alignleft">
              <header>Invoice {data.invoice_id}</header>
              <article></article>
            </div>
            <div className="alignright">
              <header>Bill To:</header>
              <article>
                <p>
                  <strong>
                    {auth.first_name} {auth.last_name}
                  </strong>
                </p>
                <p>
                  <strong>{auth.email}</strong>
                </p>
              </article>
            </div>
          </section>
          <section id="items">
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="name">{data.subscription}</td>
                  <td className="price">${data.amount}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="name">Subtotal:</td>
                  <td className="price">${data.amount}</td>
                </tr>
                <tr>
                  <td className="name">
                    <strong>Total Price:</strong>
                  </td>
                  <td className="price">
                    <strong>${data.amount}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="name">Payment Status:</td>
                  <td className="price">{data.payment_status}</td>
                </tr>
              </tfoot>
            </table>
          </section>
          <div
            className="print"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handlePaymentSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    // Proceed to Payment
    const reciptField = {
      first_name: auth.first_name,
      last_name: auth.last_name,
      email: auth.email,
    };
    const card = elements.getElement(CardElement);
    const result = await payWithCard(stripe, card, intent, reciptField);
    if (result.error) {
      // Error while payment. Show some error msg to the user
    } else {
      // The payment succeeded! Continue the user register.
      // Add Paid membership to user
      const res = await addUserToMembership(auth.userId, 1);
      if (res.status === 'success') {
      } else {
      }
    }
    setIsLoading(false);
  };

  const getCardContent = (type) => {
    if (type !== 'Free Trial') {
      return;
    }

    return (
      <div className="payment-section">
        <div className="update-payment stp">
          <Card bg="warning">
            <Card.Header as="h6">
              Fill the detail below for payment and upgrade to Lifetime user for just <strong>$39.99</strong>
            </Card.Header>
            <Card.Body>
              <Card.Title as="h6">Card details*</Card.Title>
              <Card.Text>
                <CardElement
                  options={options}
                  onReady={() => {}}
                  onChange={(event) => {}}
                  onBlur={() => {}}
                  onFocus={() => {}}
                />
              </Card.Text>
              <Button
                type="submit"
                className="fnt-r-14 no-border fnt-weight-600 bg-prime fnt-white fnt-poppins pt-2 pb-2 pl-4 pr-4"
                disabled={!stripe}
                onClick={handlePaymentSubmit}
              >
                Submit
                {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="membership-container">
      <div className="membership-inner">
        <div style={{ fontFamily: 'Poppins' }}>
          {memberships && !memberships.length ? (
            <div>Detail not available</div>
          ) : (
            memberships.map((detail) => {
              return (
                <div key={detail.membersihp_id}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Subscription</th>
                        <th>Expiration</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{detail.get_status}</td>
                        <td>{detail.membership}</td>
                        <td>{detail.get_expiration_date}</td>
                        <td>
                          {detail.membership === 'Lifetime' ? (
                            'N/A'
                          ) : (
                            <div
                              className="actions"
                              onClick={() => {
                                const paymentSection = document.querySelector('.payment-section');
                                paymentSection.style.height = 'auto';
                                paymentSection.style.opacity = 1;
                              }}
                            >
                              Upgrade for Lifetime Subsciption
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {getCardContent(memberType)}
                  <Table className="payment-detail">
                    <thead>
                      <tr>
                        <th>Invoice#</th>
                        <th>Subscription</th>
                        <th>Amount</th>
                        <th>Payment Status</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments && !payments.length ? (
                        <tr>
                          <td colSpan="6">Payment Detail not Available</td>
                        </tr>
                      ) : (
                        payments.map((data) => {
                          return (
                            <tr key={data.invoice_id}>
                              <td>{data.invoice_id}</td>
                              <td>{data.subscription}</td>
                              <td>${data.amount}</td>
                              <td>{data.payment_status}</td>
                              <td>{new Date(data.date).toISOString().slice(0, 10)}</td>
                              <td
                                className="actions"
                                onClick={() => {
                                  setData(data);
                                  setModalShow(true);
                                }}
                              >
                                View Receipt
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              );
            })
          )}
        </div>
      </div>
      <ViewReciptModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default MembershipWrap;
